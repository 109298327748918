import React from 'react';

// import sections
import HeroSplit from '../components/sections/HeroSplit';
import Cta from '../components/sections/Cta';

// import some required elements
import FeaturesSplit from '../components/sections/FeaturesSplit';

const Home = () => {
  return (
    <>
      <HeroSplit className="illustration-section-01" />
      <FeaturesSplit/>
      <form data-netlify="true">
        <Cta split />
      </form>
      
    </>
  );
}

export default Home;